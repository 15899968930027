<template>
  <div class="contentContainer h-full overflow-auto">

    <div class="contentContainer w-full rounded-lg h-full">
      <!-- Nuxtlayout is used for page layouts - hopefully in the future -->
      <!-- <NuxtLayout :name="layout" v-for="content in contentArray">   -->
      <NuxtLayout class="p-4 sm:min-h-0 !h-auto min-h-full" :style="backgroundStyles" :name="pageLayout">

        <div v-for="content in pageContents">

          <!-- <adminEditWrapper :content="editor.preview ? editor.editorContent : content" v-if="adminModeActive">
                        <component :key="content.content_id"
                            v-if="!editor.preview && allowedTemplates.includes(content.component.replace('.jsp', '').toLowerCase())"
                            :is="content.component.replace('.jsp', '').toLocaleLowerCase()"
                            :content="content.longDescription != null ? content.longDescription : 'longDescription does not exist'">
                        </component>
                        {{ editor.editorContent.longDescription }}
                        <ClientOnly>
                            <component :key="editor.editorContent.content_id"
                                v-if="editor.editorContent.longDescription && allowedTemplates.includes(content.component.replace('.jsp', '').toLowerCase())"
                                :is="content.component.replace('.jsp', '').toLocaleLowerCase()"
                                :content="'PREVIRERWERWER'+editor.editorContent.longDescription">
                            </component>
                        </ClientOnly>
                    </adminEditWrapper> -->

          <component :key="content.content_id"
            v-if="allowedTemplates.includes(content.component.replace('.jsp', '').toLowerCase()) && !adminModeActive"
            :is="content.component.replace('.jsp', '').toLocaleLowerCase()" :content="content.longDescription">
          </component>
        </div>

      </NuxtLayout>

    </div>
  </div>
</template>



<script setup lang="ts">
import type { PageContents } from '~/types/backendtypes';
import { useEditorStore } from '@/stores/admin/editorStore'
import { useAdminStore } from '@/stores/admin/adminStore'
import { storeToRefs } from 'pinia'
import type { LayoutKey } from '#build/types/layouts';
import { useAPI } from '~/composables/useApi'

const background = ref('');
const allowedTemplates = ref<string[]>([]);
const pageContents = ref<PageContents[]>([]);
const pageLayout = ref<LayoutKey>('');

const reqUrl = useRequestURL();
const config = useRuntimeConfig();
const api = useAPI();
const route = useRoute();
const { gtag, initialize } = useGtag()

const { toolbar, adminModeActive } = storeToRefs(useAdminStore());
const { editor } = storeToRefs(useEditorStore());

const backgroundStyles = computed(() => ({
  backgroundImage: background.value ? `linear-gradient(to right, rgba(250, 243, 229, 1) 20%, rgba(255, 255, 255, 0) 50%), url(${background.value})` : '',
  backgroundPosition: 'left top,right top',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: '100% 100%, auto '
}));

try {
  const data = await api.getPage(route.path);
  const { metaData, pageContents: fetchedPageContents, pageProperties } = data;

  allowedTemplates.value = ["simplecontentelementhtml4", "contact"];
  pageContents.value = fetchedPageContents;

  const MEASURE_ID = pageProperties.find(x => x.name == 'googleanalytics')?.property_value;
/*   useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${MEASURE_ID}`,
        tagPosition: 'head',
        async: true,
      }
    ]
  }); */
  if(config.public.WEBCMS_ENV == 'production') {
    initialize(MEASURE_ID)
  }

  const pageName = metaData.name;

  const { $getPageProperty, $pageLayout, $seoObjectCreator } = useNuxtApp();

  const backgroundImg = $getPageProperty("background", pageProperties);
  background.value = backgroundImg ? `${config.public.WEBCMS_ENV != 'development' ? backgroundImg?.property_value : config.public.API_BASE + '/' + backgroundImg?.property_value}` : '';

  pageLayout.value = await $pageLayout(metaData.pageTemplate);

  const seoObj = $seoObjectCreator(metaData, reqUrl);
  useServerSeoMeta(seoObj);
  useHead({
    link: [
      {
        rel: 'canonical',
        href: `https://${reqUrl.host}${reqUrl.pathname}`
      },
    ],
  });
} catch (err: any) {
  console.log(err);
  throw showError({ status: err.statusCode, statusText: err.statusMessage, fatal: false });
}
</script>